.main{
    width: 100%;
    min-height: 100dvh;
    background: linear-gradient(to bottom,#3A7BD5,#4F46E5);
}

.header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 10px 10px 10px 10px;
}

.container{
    width: 100%;
    padding: 20px;
}

.sub_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.center_form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.subscription_container{
    width: 350px;
    background-color: #fff;
    min-height: 200px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

.logo_container{
    width: 100%;
    display: grid;
    place-content: center;
}

.logo_header{
    width: 100%;
    padding: 10px 10px 10px 10px;
    border-radius: 50%;
    background-color: #4F46E5;
    overflow: hidden;
}

.logo{
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.message_container{
    width: 100%;
    border: 1px solid #4F46E5;
    background: #3a7bd57b;
    border-left: 2px solid #332ae9;
    padding: 20px 8px 20px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.message{
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #333;
    font-family: 'Montserrat',sans-serif;
}

.icon_container{
    width: 70px;
    height: 70px;
    background: #332ae9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.icon{
    color: white;
    font-size: 24px;
}

.input_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px 0 20px;
}

.text{
    width: 100%;
    font-size: 20px;
    font-family: 'Montserrat',sans-serif;
    color: #343A40;
    font-weight: 600;
}

.terms_section,.terms_section_right{
    width: 100%;
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 4px;
}

.terms_section_right{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.title{
    font-size: 24px;
    color: #343A40;
    font-family: 'Montserrat',sans-serif;
}

.list_section,.list_section_right{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    margin-left: -20px;
}

.list_section_right{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    list-style-type: none;
}

.list_section li,.list_section_right li{
    color: #343A40;
    font-size: 15px;
    font-family: 'Montserrat',sans-serif;
}

@media screen and (max-width:800px){
    .subscription_container{
        width: 100%;
    }
}