.loader_center{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #4167DA;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    } */

    .loader {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: radial-gradient(farthest-side,#4167DA 94%,#0000) top/9px 9px no-repeat,
               conic-gradient(#0000 30%,#4167DA);
        -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
        animation: spinner-c7wet2 1s infinite linear;
     }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

    @keyframes spinner-c7wet2 {
        100% {
           transform: rotate(1turn);
        }
     }



