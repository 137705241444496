.container{
    width: 100%;
    min-height: 100dvh;
    /* background: linear-gradient(to bottom,#04010f,#0e0239); */
    /* background: radial-gradient(50% 50% at 50% 50%,#3A7BD5,#4F46E5); */
    /* background: radial-gradient(50% 50% at 50% 50%,#09031f,#04010f); */
    background: linear-gradient(to bottom,#3A7BD5,#4F46E5);
    padding: 20dvh 0 60px 0;
}

.sub_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.card_center{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.card{
    width: 350px;
    background-color: #fff;
    min-height: 300px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

.logo_container{
    width: 100%;
    display: grid;
    place-content: center;
}

.logo_header{
    width: 100%;
    padding: 10px 10px 10px 10px;
    border-radius: 50%;
    background-color: #372ed7;
    box-shadow: 0 0 0 4px #4F46E5;
    overflow: hidden;
}

.logo{
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.title_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap:10px;
}

.title{
    width: 100%;
    text-align: center;
    color: #343A40;
    font-size: 28px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 800;
    margin: 0;
    padding: 0;
}

.desc{
    width: 100%;
    text-align: center;
    color: #343A40;
    font-size: 20px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 800;
    margin: 0;
    padding: 0;
}